var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Nombre del proveedor","label-for":"name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null},model:{value:(_vm.providerData.user.names),callback:function ($$v) {_vm.$set(_vm.providerData.user, "names", $$v)},expression:"providerData.user.names"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"rubro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Seleccionar Rubro","label-for":"business_area_id"}},[_c('v-select',{attrs:{"id":"business_area_id","placeholder":"Seleccione","label":"name","options":_vm.business_areas,"state":errors.length > 0 ? false:null},on:{"input":function($event){return _vm.onBusinessAreaChange($event)}},model:{value:(_vm.providerData.business_area),callback:function ($$v) {_vm.$set(_vm.providerData, "business_area", $$v)},expression:"providerData.business_area"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.tariff_allowed)?_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"tarifario"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Seleccionar Tarifario","label-for":"tarifario_id"}},[_c('v-select',{attrs:{"id":"tarifario_id","placeholder":"Seleccione","label":"type","options":_vm.tariffs,"state":errors.length > 0 ? false:null},model:{value:(_vm.providerData.tariff),callback:function ($$v) {_vm.$set(_vm.providerData, "tariff", $$v)},expression:"providerData.tariff"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,132025405)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"RUC","label-for":"ruc"}},[_c('validation-provider',{attrs:{"rules":"required","name":"ruc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ruc","state":errors.length > 0 ? false:null},model:{value:(_vm.providerData.ruc),callback:function ($$v) {_vm.$set(_vm.providerData, "ruc", $$v)},expression:"providerData.ruc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Correo electrónico","label-for":"email"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"correo electrónico"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null},model:{value:(_vm.providerData.user.email),callback:function ($$v) {_vm.$set(_vm.providerData.user, "email", $$v)},expression:"providerData.user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Fecha de inicio del Ciclo","label-for":"start_date_cycle"}},[_c('b-form-input',{attrs:{"type":"text","readonly":""},model:{value:(_vm.start_date_cycle),callback:function ($$v) {_vm.start_date_cycle=$$v},expression:"start_date_cycle"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Número(s) de contrato(s)","label-for":"contract_number"}},[_c('b-form-input',{attrs:{"id":"contract_number"},model:{value:(_vm.providerData.contract_numbers),callback:function ($$v) {_vm.$set(_vm.providerData, "contract_numbers", $$v)},expression:"providerData.contract_numbers"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Contraseña (en caso desee actualizarla, complete el siguiente campo, sino, dejar vacío)","label-for":"a-password"}},[_c('b-form-input',{attrs:{"id":"a-password","placeholder":"********","autocomplete":"new-password"},model:{value:(_vm.providerData.password),callback:function ($$v) {_vm.$set(_vm.providerData, "password", $$v)},expression:"providerData.password"}})],1)],1)],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":_vm.updateInformation}},[_vm._v(" Guardar cambios ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }