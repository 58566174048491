import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchProviders(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/providers', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchProvider(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/provider/${id}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addProvider(ctx, userData) {
            return new Promise((resolve, reject) => {

                const payload = {
                    business_area_id: userData.business_area.id,
                    tariff_id: (userData.tariff && userData.tariff.id ? userData.tariff.id : null),
                    name: userData.name,
                    ruc: userData.ruc,
                    sdcISO: userData.sdcISO,
                    contract_numbers: userData.contract_numbers,
                    email: userData.email
                }

                axios
                    .post('/provider/create', payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateProvider(ctx, { id, providerData }) {
            return new Promise((resolve, reject) => {

                const payload = {
                    user_id: providerData.id,
                    business_area_id: providerData.business_area_id,
                    tariff_id: providerData.tariff_id,
                    name: providerData.name,
                    ruc: providerData.ruc,
                    start_date_cycle: providerData.start_date_cycle,
                    contract_numbers: providerData.contract_numbers,
                    email: providerData.email,
                    password: providerData.password
                }

                axios
                    .put(`/provider/${id}/update`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteProvider(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/provider/${id}/delete`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}