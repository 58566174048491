<template>
    <div>

        <!-- Form: Info Form -->
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>

                    <!-- Field: Name -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="Nombre del proveedor"
                            label-for="name"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="nombre"
                            >
                                <b-form-input
                                    id="name"
                                    :state="errors.length > 0 ? false:null"
                                    v-model="providerData.user.names"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    
                    <!-- Rubro -->
                    <b-col cols="12" md="4">
                        <validation-provider
                            #default="{ errors }"
                            name="rubro"
                            rules="required"
                        >
                            <b-form-group
                                label="Seleccionar Rubro"
                                label-for="business_area_id"
                            >
                                <v-select
                                    id="business_area_id"
                                    v-model="providerData.business_area"
									@input="onBusinessAreaChange($event)"
									placeholder="Seleccione"
                                    label="name"
                                    :options="business_areas"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    
                    <!-- Tarifario -->
                    <b-col cols="12" md="4"  v-if="tariff_allowed">
                        <validation-provider
                            #default="{ errors }"
                            name="tarifario"
                        >
                            <b-form-group
                                label="Seleccionar Tarifario"
                                label-for="tarifario_id"
                            >
                                <v-select
                                    id="tarifario_id"
                                    v-model="providerData.tariff"
									placeholder="Seleccione"
                                    label="type"
                                    :options="tariffs"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    
                    <!-- Field: Ruc -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="RUC"
                            label-for="ruc"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="ruc"
                            >
                                <b-form-input
                                    id="ruc"
                                    :state="errors.length > 0 ? false:null"
                                    v-model="providerData.ruc"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Field: Email -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="Correo electrónico"
                            label-for="email"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required|email"
                                name="correo electrónico"
                            >
                                <b-form-input
                                    id="email"
                                    :state="errors.length > 0 ? false:null"
                                    v-model="providerData.user.email"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    
                    <!-- Start date cycle -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="Fecha de inicio del Ciclo"
                            label-for="start_date_cycle"
                        >
                            <b-form-input
                                type="text"
                                v-model="start_date_cycle"
                                readonly
                            />
                        </b-form-group>
                    </b-col>   

                    <!-- Field: Contract Numbers -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="Número(s) de contrato(s)"
                            label-for="contract_number"
                        >
                            <b-form-input
                                id="contract_number"
                                v-model="providerData.contract_numbers"
                            />
                        </b-form-group>
                    </b-col>                 
                    
                    <!-- Field: Password -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="Contraseña (en caso desee actualizarla, complete el siguiente campo, sino, dejar vacío)"
                            label-for="a-password"
                        >
                            <b-form-input
                                id="a-password"
                                v-model="providerData.password"
                                placeholder="********"
                                autocomplete="new-password"
                            />
                        </b-form-group>
                    </b-col>

                </b-row>
            </b-form>
        </validation-observer>

        <!-- Action Buttons -->
        <b-button
            @click="updateInformation"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
            Guardar cambios
        </b-button>
    </div>
</template>

<script>

    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { ref, onBeforeMount } from '@vue/composition-api';
    import { required } from '@validations';
    import vSelect from 'vue-select';
    import flatPickr from 'vue-flatpickr-component'
    import store from '@/store';
    import axios from '@axios';
    import moment from 'moment';
    
    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            vSelect,
            flatPickr,
            
            required
        },
        props: {
            providerData: {
                type: Object,
                required: true
            }
        },
        data() {                                   
            return {
                required,
                moment,
            }
        }, 
        setup(props) {

            const start_date_cycle = moment(props.providerData.start_date_cycle).format('DD-MM-YYYY');
            
            const business_areas = ref([]);
            const tariffs = ref([]);
			const tariff_allowed = ref(false);
			const MINA_ID = ref(1);

			onBeforeMount( () => {				
                getBusinessAreas();

				if (props.providerData.business_area.id === MINA_ID.value) {
					tariff_allowed.value = true;
					getTariffs(props.providerData.business_area.id);
				}
            });


			const getBusinessAreas = async () => {
				const response = await axios.get('/business-area/select')
				business_areas.value = response.data;
			}

			const getTariffs = async (business_area_id) => {
				if (!business_area_id) {
					tariffs.value = [];
					return;
				}

				const response = await axios.get(`/tariffs/select?business_area_id=${business_area_id}`)
				tariffs.value = response.data;
			}

			
            const onBusinessAreaChange = (val) => {
				if (val && val.id === MINA_ID.value) {
					tariff_allowed.value = true;
					getTariffs(val.id)
					return;
				}

				tariff_allowed.value = false;
				props.providerData.tariff = null;
				return;
            };
            
            // Use Toast
            const toast = useToast();

            const simpleRules = ref(null);

            const updateInformation = () =>
            {
                simpleRules.value.validate()
                    .then(success => {
                        if (success) {
                            
							let tariff_id = null
							if (props.providerData.tariff && tariff_allowed.value) {
								tariff_id = props.providerData.tariff.id
							}

                            let providerData = {
                                ...props.providerData.user,
                                name: props.providerData.user.names,
                                ruc: props.providerData.ruc,
                                contract_numbers: props.providerData.contract_numbers,
                                business_area_id: props.providerData.business_area.id,
                                tariff_id,
                                password: props.providerData.password
                            };


                            store.dispatch('app-provider/updateProvider', { id: props.providerData.id, providerData })
                                .then((response) => {

                                    props.providerData.password = '';

                                    toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: response.data.message,
                                            icon: 'CheckIcon',
                                            variant: 'success'
                                        }
                                    });
                                })
                                .catch( (err) => {
                                    let message = err.response.data.message ? err.response.data.message : 'Error al actualizar la información del proveedor.';
                                    toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: message,
                                            icon: 'AlertTriangleIcon',
                                            variant: 'danger'
                                        }
                                    });
                                });
                        }
                    });
            };

            return {
                updateInformation,
                business_areas,
                tariffs,
                
                simpleRules,
                start_date_cycle,

				tariff_allowed,
				onBusinessAreaChange,
            }
        }
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>